import * as React from "react";
import "./HomePage.css";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


type HomePageProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];


const HomePage: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  console.log(t)
  console.log(i18n)
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);

  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
    // i18next.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
  // function onLanguageClick(code){
  //   i18next.changeLanguage(code);
  // }


  return (
    <div className="aligncenter">
      <Grid container spacing={2}>
        <Grid xs={8} sm={8} md={8} lg={8}>

        </Grid>
        <Grid xs={4} sm={4} md={4} lg={4} >
          <Box sx={{ mixWidth: 120 }} >
            <FormControl style={{ minWidth: 150 }}  >
              {/* <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel> */}
              <Select
                size="small"
                sx={{ maxWidth: 120, color: '#906427', variant: "outlined" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={showlanguage}
              // label={t('language')}
              // onChange={handleChange}
              >
                {languages.map(({ code, name }) => (
                  <MenuItem sx={{ color: '#906427' }} value={code} onClick={() => onLanguageClick(code)}>
                    {name}
                  </MenuItem>
                )
                )}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ color: '#906427' }} >
        <h1>
          {t('Dej-Udom Library')}
        </h1>
      </Box>
      <div className="aligncenter">
        <Paper sx={{ height: "70vh", width: "90%" }}>
          <Iframe url="https://anyflip.com/bookcase/oqfdy" width="100%" height="100%" id="myId" display="inline" position="relative" />
        </Paper>
      </div>
      {/* <iframe  width="70%" height="425px"  src="https://anyflip.com/bookcase/oqfdy" ></iframe> */}

    </div>);
};

export default HomePage;
