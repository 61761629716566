import * as React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Paper,Grid } from "@mui/material";
import pdf from '../assets/DCMPrivacyPolicy.pdf';


const Footer: React.FC<any> = () => {
  const { t,i18n } = useTranslation();
  return (
    <Grid container spacing={0}  alignItems="center"
    justifyContent="center" direction="column">
       <Grid  xs={12} sm={12} md={12} lg={12} container spacing={0}  alignItems="center"
    justifyContent="center" direction="column">
       {t('Copyright')}
    </Grid>

    </Grid>
//     <footer id="footer">
//     <p id="copyright">{t('Copyright')}</p>
//     {/* <p><a href={pdf} target="_blank" rel="noreferrer" id="PPlink">{t('Our Privacy Policy')}</a></p> */}
// </footer>
  );
}

export default Footer;